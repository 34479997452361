<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"></div>
				</div>
			</div>
		</div>
		<div class="about-content">
			<el-row :gutter="20" class="nav nav-left">
				<el-col :span="24">
					<h2>阿克索健康国际简介</h2>
				</el-col>
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际·秉承健康女神之精神，由中国三甲医院资深医务工作者和美国、加拿大等多国资深医务工作者共同创建，致力于为中国客户搭建直通海外的国际化、专业化海外医疗服务机构。专注为国内客户提供美国加拿大专家会诊、赴美就医、高端体检、精准医疗、医护培训等一站式海外医疗咨询服务。让高端客户享受国际领先、可信赖的医学治疗方案，和贵宾式的服务关怀，享受更健康、更成功的人生。
						</p>
						<p>阿克索(Akso)是希腊神话中的健康女神，其形象为一个年轻女子手持装有蛇的碗，她是在希腊宗教中主司卫生安全、医药治疗的医药女神。阿克索在西方文学的形象相当于中国传说中的扁鹊。即守护人类的健康之神。
						</p>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/1.png" />
					</div>
				</el-col>
			</el-row>

			<el-row :gutter="20" class="nav nav-left">
				<el-col :span="24">
					<h2>阿克索健康国际经营项目</h2>
				</el-col>
				<el-col :span="24">
					<div class="left">
						<p>阿克索健康国际·秉承健康女神之精神，由中国三甲医院资深医务工作者和美国、加拿大等多国资深医务工作者共同创建，致力于为中国客户搭建直通海外的国际化、专业化海外医疗服务机构。专注为国内客户提供美国加拿大专家会诊、赴美就医、高端体检、精准医疗、医护培训等一站式海外医疗咨询服务。让高端客户享受国际领先、可信赖的医学治疗方案，和贵宾式的服务关怀，享受更健康、更成功的人生。
						</p>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-left">
				<el-col :span="24">
					<h2>阿克索健康国际大事记</h2>
				</el-col>
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际主办重庆市第一届北美医疗学术论坛</p>
						<div class="Underline"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/2.png" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-right">
				<el-col :span="8">
					<div class="left">
						<img src="../../image/index/3.png" />
					</div>
				</el-col>
				<el-col :span="16">
					<div class="right">
						<p>阿克索健康国际与美国三大医疗集团代表和加拿大亨河伯医院签约合作</p>
						<div class="Underline"></div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-left">
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际创始人张贵芳女士参观美国森特拉医疗集团</p>
						<div class="Underline"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/4.png" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-right">
				<el-col :span="8">
					<div class="left">
						<img src="../../image/index/5.png" />
					</div>
				</el-col>
				<el-col :span="16">
					<div class="right">
						<p>阿克索健康国际与重庆市医学会及著名三甲医院共同举办全市医生培训</p>
						<div class="Underline"></div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-left">
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际管理团队在加拿大多伦多亨伯河医院考察参观留影</p>
						<div class="Underline"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/6.png" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-right">
				<el-col :span="8">
					<div class="left">
						<img src="../../image/index/7.png" />
					</div>
				</el-col>
				<el-col :span="16">
					<div class="right">
						<p>阿克索健康国际专家团队参观国际智能化药房</p>
						<div class="Underline"></div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-left">
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际创始人张贵芳与美国外科院士、斯坦福大学医学院外科导师一行人合影</p>
						<div class="Underline"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/8.png" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-right">
				<el-col :span="8">
					<div class="left">
						<img src="../../image/index/9.png" />
					</div>
				</el-col>
				<el-col :span="16">
					<div class="right">
						<p>阿克索健康国际创始人张贵芳女士在美国南弗洛里医学院 坦帕总院USF生殖医学中心考察参观后与胚胎学专家和护 理部负责人合影、斯坦福大学医学院外科导师一行人合影</p>
						<div class="Underline"></div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-left">
				<el-col :span="16">
					<div class="left">
						<p>阿克索健康国际部分国际专家团队参加重庆市急救学术年会</p>
						<div class="Underline"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="right">
						<img src="../../image/index/10.png" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-his-right">
				<el-col :span="8">
					<div class="left">
						<img src="../../image/index/11.png" />
					</div>
				</el-col>
				<el-col :span="16">
					<div class="right">
						<p>阿克索健康国际创始人张贵芳女士与美国怀特医疗集团 高层领导在美国总统接待外宾用餐的著名餐厅用餐</p>
						<div class="Underline"></div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="nav nav-left">
				<el-col :span="24">
					<h2>阿克索健康国际价值理念</h2>
				</el-col>
				<el-col :span="24">
					<div class="left">
						<p>公司理念：以爱为基础，与世界上最优秀的个人和团队合作，健康生活，快乐工作，相信爱到极致是奇迹。</p>
						<p>公司愿景：成为人们链接世界最优质医疗机构及健康行业资源至诚守信、高效合作的 领导者。</p>
						<p>公司使命：助天下人健康，愿尽余之能力与判断力所及，为患者、家属谋幸福及利益，愿尽余之能力所及，为医务人员链接和搭建广阔的优质就业、再学习和深造平台，为医务人员谋幸福及好未来。</p>
						<p>公司核心价值观：重庆阿克索实业有限公司集团及其子公司基于共同的使命、愿景及价值体系，建立了企业文化，作为我们业务的基石。我们的业务成功和快速增长有赖于我们尊崇企业家精神和创新精神，并且始终如一地关注和满足客户的需求。新员工加入重庆阿克索实业有限公司集团的时候，需于重庆总部参加全面的入职培训和团队建设课程，该课程着重于公司的使命、愿景和价值观，而我们也会在定期的培训、团队建设训练和公司活动中再度强调这些内容。无论公司成长到哪个阶段，这强大的共同价值观让我们可以维持一贯的企业文化。
						</p>
						<p>铭记社会的责任，践行道义的担当，AKSO
							健康国际在追求仁术的道路上，正如“AKSO”其名的由来，始终传承着希腊健康女神“AKSO”之精神，坚守着希波克拉底之誓言。积极向国际化医疗前沿迈进，争创国际医疗标杆。</p>
					</div>
				</el-col>
			</el-row>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				name: ''
			};
		},
		created() {},
		methods: {
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
		},
	};
</script>

<style lang='scss'>
	.about-content {
		width: 80%;
		margin-left: 10%;

		.nav {
			text-align: center;

			h2 {
				margin: 0 auto;
				width: 300px;
				font-size: 26px;
				border-bottom: 2px solid #00A8FF;
				padding: 18px 0 8px 0;
			}
		}

		.nav-left {
			.left {
				p {
					padding: 10px 6px;
					text-indent: 2em;
					line-height: 20px;
					text-align: left;
				}
			}
		}

		.nav-his-left {
			p {
				padding-top: 150px;
				font-size: 16px;
				text-align: left;
			}


			.Underline {
				width: 135px;
				height: 2px;
				background-color: #00A8FF;
				margin-top: 10px;
			}
		}

		.nav-his-right {
			background: #F9F9F9;
			padding-top: 10px;
			padding-bottom: 10px;

			p {
				padding-top: 150px;
				font-size: 16px;
				text-align: left;
				padding-left: 40px;
			}


			.Underline {
				width: 135px;
				height: 2px;
				background-color: #00A8FF;
				margin-top: 10px;
				margin-left: 40px;
			}
		}
	}
</style>